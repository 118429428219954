import { UAParser } from 'ua-parser-js'

const MIN_VERSION: number = 54 as const;

declare global {
    interface Window {
        NO_BUG_EMAIL?: boolean;
    }
}

const showMessage = (title: string, message: string): void => {
    window.addEventListener('DOMContentLoaded', () => {
        const header: HTMLElement | null = document.querySelector('.headback')
        if (!header) {
            return
        }
        const content: HTMLElement | null = header.querySelector(':scope > .content')
        if (!content) {
            return
        }

        const warning: HTMLElement = document.createElement('div')
        warning.className = 'warning--unsuported-browser'

        const inner: HTMLElement = document.createElement('div')
        inner.appendChild(((): HTMLHeadingElement => {
            const elem = document.createElement('h2')
            elem.textContent = `${title}:`
            return elem
        })())
        inner.appendChild(((): HTMLParagraphElement => {
            const elem = document.createElement('p')
            elem.textContent = message
            return elem
        })())
        warning.appendChild(inner)

        header.insertBefore(warning, content)
    })
}

const showUnsupported = (): void => {
    window.NO_BUG_EMAIL = true;
    showMessage(
        'Unsupported browser',
        'OPAS officially supports Chrome and Edge. If you experience any issues, please consider using one of these browsers.'
    )
}

const showOld = (): void => {
    window.NO_BUG_EMAIL = true;
    showMessage(
        'Your browser is out-of-date',
        'You might experience some issues. Please update at your earliest convenience.'
    )
}

export default function browserCheck(): void {
    const {engine:{name: engine, version}} = UAParser()

    if (!engine) {
        return
    }

    if (engine !== 'Blink') {
        showUnsupported();
        return;
    }

    if (!version) {
        return;
    }

    const versionArr = version.split('.').map((v) => Number(v))
    if (!versionArr.length || !versionArr[0]) {
        return;
    }

    if (versionArr[0] < MIN_VERSION) {
        showOld();
        return;
    }
}