type TableSortOptions = {
    headerSelector: string
    stateVar: TableSortState
    sortFunc: JQuerySortFunc
}

type JQuerySortFunc = (a: HTMLElement, b: HTMLElement) => number

type TableSortState = {
    i: number
    c: number
    inv: boolean
}

type FormLockOptions = {
    not: string
}

interface JQuery {
    sort: (sortFunc: JQuerySortFunc) => this

    tooltips: () => this
    tableSort: (initOptions: Partial<TableSortOptions>) => this
    lock: (args: Partial<FormLockOptions>) => this
    unlock: (args: Partial<FormLockOptions>) => this
}

(($) => {
    $.fn.tooltips = function (): JQuery<HTMLElement> {
        return $("[description]", this).each(function () {
            const $this: JQuery<HTMLElement> = $(this)

            const desc: string = $this.attr("description") || ''
            if (!desc) {
                return
            }

            let type: string | undefined = $this.attr("description-type")
            if (type) {
                if (type === "warning") {
                    type = "warning";
                } else {
                    type = undefined;
                }
                $this.removeAttr("description-type");
            }
            if (type === undefined) {
                type = "info";
            }

            let pos: string | undefined = $this.attr("description-position")
            if (pos) {
                if (pos === "left") {
                    pos = "left";
                } else {
                    pos = undefined;
                }
                $this.removeAttr("description-position");
            }
            if (pos === undefined) {
                pos = "right";
            }

            const $anchor: JQuery<HTMLElement> = $("<span>")
                .addClass("tooltip")
                .addClass("tooltip-" + type)
                .addClass("tooltip-" + pos)
                .on('mouseenter', function () {
                    $(".tooltip-content", this).addClass("tooltip-show");
                })
                .on('mouseleave', function () {
                    $(".tooltip-content", this).removeClass("tooltip-show");
                });

            const $tooltip: JQuery<HTMLElement> = $("<div>")
                .addClass("tooltip-content")
                .appendTo($anchor);

            const title: string | undefined = $this.attr("title")
            if (title) {
                $tooltip.append($("<h1>").html(title));
                $this.removeAttr("title");
            }

            $tooltip.append($("<p>").html(desc));
            $this.removeAttr("description");

            $this.addClass("tooltip-enabled");
            if (pos === "left") {
                $this.prepend($anchor);
            } else {
                $this.append($anchor);
            }
        })
    }
    $(() => $("body").tooltips());

    $(() => {
        const getOverlay = (target: string | undefined): JQuery<HTMLElement> => {
            let $target: JQuery<HTMLElement> | undefined

            if (target) {
                $target = $(target).clone();
                if (!$target.length) {
                    $target = undefined;
                }
            }

            return $target || $('<p class="freezeform-overlay-content">Please wait...</p>');
        }

        $('form[data-toggle="freezeform"]').each(function () {
            const $this: JQuery<HTMLElement> = $(this)
            let $overlay: JQuery<HTMLElement> = getOverlay($this.data("freezeform-target"))

            $overlay.addClass("freezeform-overlay-content").css("display", "");
            $overlay = $("<div>").addClass("freezeform-overlay").append($overlay);

            const token: number = new Date().getTime();
            $(this).append($('<input type="hidden" name="freezeform_token">').val(token));

            $(this).on("submit", () => {
                var docHeight: number = $(document).height()!;

                $overlay
                    .css({
                        display: "",
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: docHeight + "px",
                        "z-index": "1000"
                    })
                    .prependTo("body");

                const tokenRegEx: RegExp = new RegExp("freezeformToken=" + token);
                const tokenCheck: number = window.setInterval((): void => {
                    const match = document.cookie.match(tokenRegEx);
                    if (match === null) {
                        return
                    }

                    document.cookie = "freezeformToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
                    $overlay.remove();
                    window.clearInterval(tokenCheck);
                }, 500)
            })
        })
    })

    $(() => {
        $("table.rows-clickable tr").on("click", function (e) {
            const href: string | undefined = $(e.currentTarget).data("href");

            if (href) {
                e.preventDefault()
                document.location.href = href;
            }
        })
    })

    $.fn.tableSort = function (initOptions: Partial<TableSortOptions>): JQuery<HTMLElement> {
        return this.each(function (_i: number, table: HTMLElement): void {
            const sortFunc: JQuerySortFunc = (a: HTMLElement, b: HTMLElement): number => {
                const $a: JQuery<HTMLElement> = $(a);
                const $b: JQuery<HTMLElement> = $(b);

                const v: Array<number | string> = [
                    $a.children().eq(options.stateVar.c),
                    $b.children().eq(options.stateVar.c)
                ].map(($e) => {
                    if ($e.data("sortvalue") === undefined) {
                        if ($e.data("sortnumeric") !== undefined) {
                            $e.data("sortvalue", Number($e.data("sortnumeric")));
                        } else {
                            $e.data("sortvalue", String($e.text()).toLowerCase());
                        }
                    }

                    return $e.data("sortvalue");
                })

                if (v[0] > v[1]) {
                    return options.stateVar.inv ? -1 : 1;
                } else if (v[0] < v[1]) {
                    return options.stateVar.inv ? 1 : -1;
                } else {
                    return 0;
                }
            }

            const sortRows = (): void => {
                $headers.removeClass("sortable-asc").removeClass("sortable-desc");

                $tr.sort(options.sortFunc).detach().appendTo($("tbody", table));

                $headers
                    .eq(options.stateVar.i)
                    .toggleClass("sortable-asc", !options.stateVar.inv)
                    .toggleClass("sortable-desc", options.stateVar.inv);
            }

            if (table.nodeName !== "TABLE") {
                return;
            }

            const state: TableSortState = {
                i: 0,
                c: 0,
                inv: false
            }
            const options: TableSortOptions = $.extend(
                {
                    headerSelector: "thead th",
                    sortFunc,
                    stateVar: state
                },
                initOptions
            )

            const $headers: JQuery<HTMLElement> = $(options.headerSelector, table);
            const $tr: JQuery<HTMLElement> = $("tbody tr", table);

            $headers.each(function (i: number, th: HTMLElement): void {
                $(th).addClass("sortable").on('click', function (): void {
                    var $this: JQuery<HTMLElement> = $(this);

                    options.stateVar.c = $this.index();
                    options.stateVar.i = i;
                    options.stateVar.inv = $this.hasClass("sortable-asc");
                    sortRows();
                });
            });

            if (!$.isEmptyObject(options.stateVar)) {
                sortRows();
            }
        })
    }
    $(() => $("table[data-table-sortable]").tableSort({
        headerSelector: "thead th.sortable"
    }));

    (() => {
        const formLock = (lock: boolean, args: Partial<FormLockOptions>): ((i: number, elem: HTMLElement) => void) => {
            const options: FormLockOptions = $.extend(
                {
                    not: ""
                },
                args
            );

            return (_i: number, elem: HTMLElement): void => {
                if (elem.nodeName !== "FORM") {
                    return;
                }

                const $form: JQuery<HTMLElement> = $(elem)
                let $elems: JQuery<HTMLElement> = $(":input", $form)

                if (options.not) {
                    $elems = $elems.not(options.not);
                }

                $form.toggleClass("form-locked", lock);
                $elems.prop("disabled", lock).toggleClass("input-locked", lock);
            }
        }

        $.fn.lock = function (args: Partial<FormLockOptions>): JQuery<HTMLElement> {
            return this.each(formLock(true, args));
        }

        $.fn.unlock = function (args: Partial<FormLockOptions>): JQuery<HTMLElement> {
            return this.each(formLock(false, args));
        }
    })()
})($)