import "./polyfills"
import "./jQuery"
import browserCheck from "./browserCheck"

import "@/scss/app.scss"

interface Ajax {
    ajax: (url: string, data?: Record<string, any>, method?: string, busyLabel?: string | false) => JQuery.jqXHR
    get: (url: string, data?: Record<string, any>, busyLabel?: string | false) => JQuery.jqXHR
    post: (url: string, data?: Record<string, any>, busyLabel?: string | false) => JQuery.jqXHR
    delete: (url: string, data?: Record<string, any>, busyLabel?: string | false) => JQuery.jqXHR
}

interface Session {
    get: (key: string) => string | undefined
    getArray: (key: string) => Array<string>
    set: (key: string, value: string) => void
    remove: (key: string) => void
    clear: () => void
}

const FlashTypes = {
    INFO: 1,
    SUCCESS: 2,
    WARNING: 3,
    ERROR: 4,
} as const

type FlashType = typeof FlashTypes[keyof typeof FlashTypes]

interface Flash {
    set: (msg: string, type?: FlashType) => void
    show: () => void,
    INFO: typeof FlashTypes.INFO,
    SUCCESS: typeof FlashTypes.SUCCESS,
    ERROR: typeof FlashTypes.ERROR,
    WARNING: typeof FlashTypes.WARNING,
}

const COOKIE_EXPIRE = 'Thu, 01 Jan 1970 00:00:00 UTC' as const

const systemMeta: HTMLMetaElement | null = document.querySelector('meta[name="App.system"]')
const apiMeta: HTMLMetaElement | null = document.querySelector('meta[name="App.apiUrl"]')

const isValidEmail = (email: string): boolean => {
    const emailRegex:RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/i;

    return emailRegex.test(email);
}

const Session = (): Session => {
    const cookieName: string = "__session"
    const cookieRegExp: RegExp = new RegExp("^" + cookieName + "_([^=]+)=(.*)$");
    let sessionData: Record<string, string | Array<string>> = {}
    document.cookie.split(";").forEach((cookie: string): void => {
        const cookieData = cookieRegExp.exec(cookie.trim())
        if (cookieData) {
            sessionData[cookieData[1]] = cookieData[2]
        }
    })

    const storeCookie = (key: string, value: string): void => {
        document.cookie = cookieName + "_" + key + "=" + value + "; path=/" + App.system;
    }

    const deleteCookie = (key: string): void => {
        document.cookie =
            cookieName +
            "_" +
            key +
            "=; expires=" + COOKIE_EXPIRE + "; path=/" +
            App.system;
    }

    const isArrayValue = (value: string | Array<string>): value is Array<string> => {
        return Array.isArray(value)
    }

    return {
        get: (key: string): string | undefined => {
            if (!sessionData[key]) {
                return undefined
            }

            const val = sessionData[key]
            return isArrayValue(val) ? val.join(',') : val
        },
        getArray: (key: string): Array<string> => {
            if (!sessionData[key]) {
                return []
            }

            const val = sessionData[key]
            if (!isArrayValue(val)) {
                sessionData[key] = val.split(",");
            }

            return sessionData[key] as Array<string>
        },
        set: (key: string, value: string): void => {
            sessionData[key] = value;
            storeCookie(key, value);
        },
        remove: (key: string): void => {
            delete sessionData[key];
            deleteCookie(key);
        },
        clear: (): void => {
            Object.keys(sessionData).forEach((k) => deleteCookie(k))

            sessionData = {}
        }
    }
}

const Flash = (): Flash => {
    const COOKIE_KEY = '__flash' as const

    return {
        INFO: FlashTypes.INFO,
        SUCCESS: FlashTypes.SUCCESS,
        ERROR: FlashTypes.ERROR,
        WARNING: FlashTypes.WARNING,
        set: (message: string, type: FlashType = FlashTypes.INFO): void => {
            document.cookie = COOKIE_KEY + "=" + encodeURIComponent(JSON.stringify({ message, type })) + "; path=/";
        },
        show: (): void => {
            const flash = document.cookie.split(";")
                .find((cookie: string): boolean => cookie.trim().indexOf(`${COOKIE_KEY}=`) === 0)

            if (!flash) {
                return
            }

            document.cookie = `${COOKIE_KEY}=; expires=${COOKIE_EXPIRE}; path=/`;
            let message: { message: string, type: FlashType }
            try {
                message = JSON.parse(decodeURIComponent(flash.substring(COOKIE_KEY.length + 1)))
            } catch (e) {
                return
            }

            const flashCardContainer = document.createElement('div')
            flashCardContainer.className = 'flash-card'
            switch (message.type) {
                case FlashTypes.INFO:
                    flashCardContainer.dataset.type = 'info'
                    break;
                case FlashTypes.SUCCESS:
                    flashCardContainer.dataset.type = 'success'
                    break;
                case FlashTypes.WARNING:
                    flashCardContainer.dataset.type = 'warning'
                    break;
                case FlashTypes.ERROR:
                    flashCardContainer.dataset.type = 'error'
                    break;
            }
            const flashCard = document.createElement('div')
            flashCard.textContent = message.message
            flashCardContainer.appendChild(flashCard)
            document.body.appendChild(flashCardContainer)
            setTimeout(() => {
                flashCard.style.opacity = '1'
                setTimeout(() => {
                    flashCard.style.opacity = '0'
                    setTimeout(() => {
                        document.body.removeChild(flashCardContainer)
                    }, 1000)
                }, 2000 + (message.message.length * 100))
            }, 100)

            console.log(message)
        }
    }
}

const modalLoading = (): ((msg?: any) => void) => {
    let modalLoading: HTMLElement
    let modalLoadingInner: HTMLElement

    return (msg?: any): void => {
        if (!msg && !modalLoading) {
            return;
        }

        if (!modalLoading) {
            modalLoading = document.createElement('div')
            modalLoading.style.backgroundColor = '#fff'
            modalLoading.style.display = 'table'
            modalLoading.style.opacity = '0.8'
            modalLoading.style.filter = 'alpha(opacity=80)'
            modalLoading.style.position = 'fixed'
            modalLoading.style.top = '0'
            modalLoading.style.left = '0'
            modalLoading.style.width = '100%'
            modalLoading.style.height = '100%'
            modalLoading.style.zIndex = '9999'

            modalLoadingInner = document.createElement('div')
            modalLoadingInner.style.display = 'table-cell'
            modalLoadingInner.style.verticalAlign = 'middle'
            modalLoadingInner.style.textAlign = 'center'
            modalLoadingInner.style.fontSize = '3em'
            modalLoading.appendChild(modalLoadingInner)
        }

        if (msg) {
            modalLoadingInner.textContent = msg
            if (!modalLoading.parentElement) {
                document.body.appendChild(modalLoading)
            }
        } else if (modalLoading.parentElement) {
            document.body.removeChild(modalLoading)
        }
    }
}

const ajax = ((): Ajax => {
    const busy: {
        enabled: boolean,
        label: string,
        $dialog: JQuery<HTMLElement> | undefined,
        $progress: JQuery<HTMLElement> | undefined,
        $progressLabel: JQuery<HTMLElement> | undefined,
    } = {
        enabled: false,
        label: '',
        $dialog: undefined,
        $progress: undefined,
        $progressLabel: undefined
    };

    const parseHTML = (raw: string): Array<Node> => {
        const html = $.parseHTML(raw);

        if (window.Debugger) {
            window.Debugger.parseHTML(html);
        }

        return html;
    }

    const beforeSend = (): void => {
        if (busy.enabled) {
            busy.$progressLabel!.text(busy.label);
            busy.$dialog!.dialog("open");
        }
    }

    const complete = (jqXHR: JQuery.jqXHR): void => {
        if (busy.enabled) {
            busy.$dialog!.dialog("close");
        }

        if (!window.Debugger) {
            return;
        }

        const debugHeader: string | null = jqXHR.getResponseHeader("X-Debug-Response");
        if (debugHeader) {
            const debug = JSON.parse("[" + debugHeader + "]");
            if (debug) {
                window.Debugger.insert(debug);
            }
        }
    }

    const ajax = (
        url: string,
        data: Record<string, any> = {},
        method?: string,
        busyLabel?: string | false
    ): JQuery.jqXHR => {
        if (busyLabel !== false) {
            busy.enabled = true
            busy.label = busyLabel || "Loading...";
            if (!busy.$dialog) {
                busy.$dialog = $("<div>")
                    .attr("id", "loading-dialog")
                    .appendTo("body")
                    .dialog({
                        modal: true,
                        dialogClass: "ui-dialog-progress",
                        height: "auto",
                        minHeight: 0,
                        autoOpen: false,
                        closeOnEscape: false,
                        resizable: false,
                        draggable: false,
                        title: ""
                    });
            }

            if (!busy.$progress) {
                busy.$progress = $("<div>").appendTo(busy.$dialog!).progressbar({
                    value: false
                });
                busy.$progressLabel = $("<div>")
                    .addClass("ui-progressbar-label")
                    .prependTo(busy.$progress);
            }
        }

        return $.ajax({
            url: url,
            type: method,
            converters: {
                "text html": parseHTML
            },
            data: data,
            beforeSend: beforeSend,
            complete: complete
        });
    }

    return {
        ajax,
        get: (url: string, data?: Record<string, any>, busyLabel?: string | false) => ajax(url, data, 'GET', busyLabel),
        post: (url: string, data?: Record<string, any>, busyLabel?: string | false) => ajax(url, data, 'POST', busyLabel),
        delete: (url: string, data?: Record<string, any>, busyLabel?: string | false) => ajax(url, data, 'DELETE', busyLabel)
    }
})()

const App = {
    system: systemMeta ? systemMeta.content : '',
    apiUrl: apiMeta ? apiMeta.content : '',
    isValidEmail,
    Session: Session(),
    Flash: Flash(),
    modalLoading: modalLoading(),
    ajax: ajax.ajax,
    get: ajax.get,
    post: ajax.post,
    delete: ajax.delete,
}

declare global {
    interface Window {
        App: typeof App
    }
}

window.App = App
window.addEventListener('DOMContentLoaded', () => {
    App.Flash.show();
})
browserCheck();

export default App