interface Date {
    setToGMT(): void;
    getSerialDate(): string;
    getShortDate(): string;
    getShortDateTime(): string;
}

interface String {
    toHtml(): string
}

Date.prototype.setToGMT = function (): void {
    this.setUTCMinutes(this.getMinutes() - this.getTimezoneOffset());
}

Date.prototype.getSerialDate = function (): string {
    return (
        "" +
        this.getFullYear() +
        ("0" + (this.getMonth() + 1)).slice(-2) +
        ("0" + this.getDate()).slice(-2)
    );
}

Date.prototype.getShortDate = function (): string {
    return (
        "" +
        ("0" + this.getDate()).slice(-2) +
        "/" +
        ("0" + (this.getMonth() + 1)).slice(-2) +
        "/" +
        this.getFullYear()
    );
}

Date.prototype.getShortDateTime = function (): string {
    return (
        this.getShortDate() +
        " " +
        ("0" + this.getHours()).slice(-2) +
        ":" +
        ("0" + this.getMinutes()).slice(-2) +
        ":" +
        ("0" + this.getSeconds()).slice(-2)
    );
};

String.prototype.toHtml = function (): string {
    let html = this.valueOf().trim();
    html = html.replace(/\n{2,}/, "</p><p>");
    html = html.replace(/\n/g, "<br />");
    return "<p>" + html + "</p>";
};